import logoLight from "@/images/logo-white.png";
import logoDark from "@/images/logo-white.png";

import flagLight from "@/images/english.png";
import flagDark from "@/images/english.png";
import flageLight from "@/images/albanian.png";
import flageDark from "@/images/albanian.png";

export const LogoImage = {
  light: logoLight,
  dark: logoDark,
};

export const flagImage = {
  light: flagLight,
  dark: flagDark,
};

export const flageImage = {
  light: flageLight,
  dark: flageDark,
};

export const NavLinks = [
  {
    name: "Heim",
    url: "/",
  },
  {
    name: "Um",
    url: "/about",
  },
  {
    name: "Dienstleistungen",
    url: "/service",
    subItems: [
      {
        name: "Dienstleistungen",
        url: "/service",
      },
      {
        name: "Webseitenentwicklung",
        url: "/website-development",
      },
      {
        name: "Grafikdesign ",
        url: "/website-graphics",
      },
      {
        name: "Digitales Marketing",
        url: "/digital-marketing",
      },
      {
        name: "Internet-Sicherheit",
        url: "/cyber-security",
      },
      {
        name: "App -Entwicklung",
        url: "/app-development",
      },
    ],
  },
  // {
  //   name: "Portfolio",
  //   url: "/portfolio",
  //   subItems: [
  //     {
  //       name: "Portfolio 01",
  //       url: "/portfolio",
  //     },
  //     {
  //       name: "Portfolio Details",
  //       url: "/portfolio-details",
  //     },
  //   ],
  // },
  // {
  //   name: "Blog",
  //   url: "/blog",
  //   subItems: [
  //     {
  //       name: "Blog 01",
  //       url: "/blog",
  //     },
  //     {
  //       name: "Blog 02",
  //       url: "/blog-2",
  //     },
  //     {
  //       name: "Blog 03",
  //       url: "/blog-3",
  //     },
  //     {
  //       name: "Blog Details",
  //       url: "/blog-single",
  //     },
  //   ],
  // },
  {
    name: "Kontakt",
    url: "/contact",
  },
];

const SliderOneData = {
  subTitle: "WILLKOMMEN ZU Solxk",
  title: "Entwicklung \n  & \n Internet-Sicherheit",
  button: {
    label: "ENTDECKE MEHR",
    url: "/about",
  },
  background: "black",
};

export const ContactInfosBlock = {
  subTitle: "unsere buros",
  title: "Büros in Ihrer Nähe",
  description:
    "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unseren Mitarbeitern die beste Schulung anbieten.",
};

export const ContactInfosList = [
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
  {
    title: "Pristina",
    infos: [
      {
        name: "10 Pristina",
      },
      {
        name: "needhelp@Solxk.com",
      },
      {
        name: "49 000 000",
      },
    ],
  },
];

export const ContactFormTitle = {
  subTitle: "Kontakt mit uns",
  title: "Schreiben Sie uns eine Nachricht",
  description:
    "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unseren Mitarbeitern die beste Schulung anbieten. ",
};

import blogImage1 from "@/images/blog/1.jpg";
import blogImage2 from "@/images/blog/2.jpg";
import blogImage3 from "@/images/blog/3.jpg";
import blogImage4 from "@/images/blog/4.jpg";
import blogImage5 from "@/images/blog/5.jpg";
import blogImage6 from "@/images/blog/6.jpg";

export const BlogData = [
  {
    title: "basic rules of running web agency business",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

import blogImageTwo1 from "@/images/blog/14.jpg";
import blogImageTwo2 from "@/images/blog/15.jpg";

export const BlogTwoData = [
  {
    title:
      "Dynamically procrastinate unique vortals with global best practices.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo1,
  },
  {
    title: "Become the best sale marketer",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage2,
  },
  {
    title: "Introducing latest mopaly features",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage3,
  },
  {
    title: "a deep understanding of our audience",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage4,
  },
  {
    title: "We build and activate brands insight",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage5,
  },
  {
    title:
      "Holisticly conceptualize backend scenarios via accurate technologies.",
    date: "20 nov",
    url: "/blog-single",
    image: blogImageTwo2,
  },
  {
    title: "experiences that connect with people",
    date: "20 nov",
    url: "/blog-single",
    image: blogImage6,
  },
];

export const BlogHomeSection = {
  subTitle: "our news & articles",
  title: "latest blog posts",
  text:
    "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
};

import blogS1 from "@/images/blog/11.jpg";
import blogS2 from "@/images/blog/12.jpg";
import blogS3 from "@/images/blog/13.jpg";

export const BlogSidebarPost = [
  {
    title: "basic rules of running web agency",
    image: blogS1,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS2,
    url: "/blog-single",
  },
  {
    title: "basic rules of running web agency",
    image: blogS3,
    url: "/blog-single",
  },
];

import commentImage1 from "@/images/blog/9.jpg";
import commentImage2 from "@/images/blog/10.jpg";

export const BlogComments = [
  {
    image: commentImage1,
    name: "David Martin",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
  {
    image: commentImage2,
    name: "Jessica Brown",
    data: "20 Nov, 2018 - 4:00 pm",
    content:
      "Lorem Ipsum is simply dummy text of the rinting and typesetting been the industry standard dummy text ever sincer condimentum purus. In non ex at ligula fringilla lobortis et not the aliquet.",
  },
];

import portfolio01 from "@/images/portfolio/1.jpg";
import portfolio02 from "@/images/portfolio/2.jpg";
import portfolio03 from "@/images/portfolio/3.jpg";
import portfolio04 from "@/images/portfolio/4.jpg";
import portfolio05 from "@/images/portfolio/5.jpg";
import portfolio06 from "@/images/portfolio/6.jpg";

export const PortfolioData = [
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio01,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio02,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio03,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio04,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio05,
    url: "/portfolio-details",
  },
  {
    title: "Design Styles",
    categories: ["all", "graphic"],
    image: portfolio06,
    url: "/portfolio-details",
  },
];

export const PortfolioFilters = [
  { name: "all" },
  { name: "graphic" },
  { name: "branding" },
  { name: "marketing" },
  { name: "logos" },
];

import portfolioD01 from "@/images/portfolio/7.jpg";
import portfolioD02 from "@/images/portfolio/8.jpg";

export const PortfolioDetailsData = {
  gallery: [{ image: portfolioD01 }, { image: portfolioD02 }],
  title: "Design Styles",
  text:
    " Tincidunt elit magnis nulla facilisis sagittis sapien nunc amet ultrices dolores sit ipsum velit purus aliquet massa fringilla leo orci. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci dolors sit amet elit amet. ",
  client: "Jessica Brown",
  categories: [
    {
      name: "Graphic,",
      url: "#",
    },
    {
      name: "Illustrations,",
      url: "#",
    },
  ],
  date: "8 Nov, 2018",
  socials: [
    {
      name: "Facebook",
      url: "#",
    },
    {
      name: "Twitter",
      url: "#",
    },
    {
      name: "Linkedin",
      url: "#",
    },
  ],
};

export const ServicePostData = [
  {
    title: "Modernes Design",
    text: "Fesselungserlebnisse mit schlanken, intuitiven Designs basteln.",
    iconName: "mei-settings",
    url: "/service-details",
  },
  {
    title: "Digitale Produktee",
    text:
      "Verwandeln Sie Ideen in hochmoderne digitale Produkte, die das Publikum fesseln.",
    iconName: "mei-transfer",
    url: "/service-details",
  },
  {
    title: "Vermarktungsstrategie",
    text:
      "Stärken Sie Ihre Marke mit datengetriebenen Marketingstrategien, die auf den Erfolg zugeschnitten sind.",
    iconName: "mei-pie-chart",
    url: "/service-details",
  },
];

// export const ServiceHomeTwoData = {
//   subTitle: "welcome to smart meipaly web agency",
//   title: "We design digital products that \n help grow businesses.",
//   text:
//     "We are committed to providing our customers with exceptional service while\n offering our employees the best training.",
// };

export const ServiceHomeThreeData = {
  subTitle: "Dienstleistungen, die wir anbieten",
  title: "Unsere Dienstleistungen",
  text:
    "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unseren Mitarbeitern die beste Schulung anbieten.",
};

export const ServicePostTwoData = {
  sectionContent: {
    title: "Mastering -Lösungen, \n Clevere Tricks einsetzen",
    // subTitle: "Solxk",
    text: "Wir bieten umfassende Lösungen für Entwicklung und Cybersicherheit.",
  },
  posts: [
    {
      title: "Webseiten entwicklung",
      iconName: "mei-web-design",
      url: "/website-development",
    },
    {
      title: "Grafiket designet",
      iconName: "mei-computer-graphic",
      url: "/website-graphics",
    },
    {
      title: "Digitales Marketing",
      iconName: "mei-development-1",
      url: "/digital-marketing",
    },
    {
      title: "Internet-Sicherheit",
      iconName: "mei-development",
      url: "/cyber-security",
    },
    {
      title: "App -Entwicklung",
      iconName: "mei-app-development",
      url: "/app-development",
    },
  ],
};

import serviceOne01 from "@/images/home_1/code.jpg";
import serviceOne02 from "@/images/home_1/digital.jpg";
import serviceOne03 from "@/images/home_1/cyber.jpg";

export const ServicePostThreeData = {
  sectionContent: {
    title: "Lassen Sie uns gemeinsam innovieren",
    subTitle: "Was wir tun",
    text:
      "  Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unserem Team erstklassige Schulungsmöglichkeiten bieten können",
  },
  posts: [
    {
      title: "Web Entwicklung",
      image: serviceOne01,
      url: "/website-development",
    },
    {
      title: "digitales Marketing",
      image: serviceOne02,
      url: "/digital-marketing",
    },
    {
      title: "Internet-Sicherheit",
      image: serviceOne03,
      url: "/cyber-security",
    },
  ],
};

export const FunfactData = [
  {
    title: "Projekte abgeschlossen",
    countNumber: 15,
  },
  {
    title: "Aktive Clients",
    countNumber: 12,
  },
  {
    title: "Tassen Kaffee",
    countNumber: 70,
  },
  {
    title: "Glückliche Kunden",
    countNumber: 39,
  },
];

import trustClient01 from "@/images/home_1/trustt.jpg";

export const TrustClientData = {
  image: trustClient01,
  title: "Wir werden von mehr als 39 Kunden vertrauen",
  text:
    "Bei Solxk, Wir sind stolz darauf, außergewöhnliche Entwicklungsdienste zu erbringen, die Erfolg und Innovation vorantreiben.Unser Team ist bestrebt, maßgeschneiderte Lösungen bereitzustellen, die den besonderen Anforderungen jedes Kunden entsprechen.Mit einem Engagement für Exzellenz und Erfolgsbilanz des Erfolgs stellen wir sicher, dass Ihre Projekte nahtlos und effizient ausgeführt werden.",
  url: "/about",
};

import ClientCarousel01 from "@/images/client/1.png";
import ClientCarousel02 from "@/images/client/2.png";
import ClientCarousel03 from "@/images/client/3.png";
import ClientCarousel04 from "@/images/client/4.png";
import ClientCarousel05 from "@/images/client/5.png";

export const ClientCarouselData = {
  sectionContent: {
    title: "Sie vertrauen uns",
    subTitle: "unsere Kunden",
    text:
      "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unseren Mitarbeitern die beste Schulung bieten.",
  },
  items: [
    {
      url: "#",
      image: ClientCarousel01,
    },
    {
      url: "#",
      image: ClientCarousel02,
    },
    // {
    //   url: "#",
    //   image: ClientCarousel03,
    // },
    // {
    //   url: "#",
    //   image: ClientCarousel04,
    // },
    // {
    //   url: "#",
    //   image: ClientCarousel05,
    // },
  ],
};

import aboutOne01 from "@/images/about/about1.jpg";
import aboutOne02 from "@/images/about/about22.jpg";

export const AboutOneData = {
  sectionContent: {
    title:
      "ErstelleLernen Sie uns kennenneller mit Solxk Company eine bessere Website",
    subTitle: "Lernen Sie uns kennen",
  },
  gallery: [aboutOne01, aboutOne02],
  counter: {
    title: "Firma begann",
    number: 2024,
  },
};

import team01 from "@/images/team/1.jpg";
import team02 from "@/images/team/2.jpg";
import team03 from "@/images/team/3.jpg";
import team04 from "@/images/team/4.jpg";
import team05 from "@/images/team/5.jpg";

export const TeamOneData = {
  sectionContent: {
    title: "Experten",
    subTitle: "Triff das Team",
    text:
      "Unser Team besteht aus leidenschaftlichen Personen, die jeweils einzigartige Talente und Fähigkeiten auf den Tisch bringen. n von visionären Entwicklern bis hin zu wachsamen Cybersicherheitsexperten spielt jedes Mitglied unseres Teams eine entscheidende Rolle bei der Bereitstellung außergewöhnlicher Lösungen für unsere Kunden.",
  },
  posts: [
    {
      image: team01,
      name: "Dede Hyseni",
      designation: "General Manager",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    // {
    //   image: team02,
    //   name: "Fidan Ymeri",
    //   designation: "Senior Developer",
    //   url: "",
    //   socials: [
    //     {
    //       name: "Facebook",
    //       url: "#",
    //     },
    //     {
    //       name: "Twitter",
    //       url: "#",
    //     },
    //     {
    //       name: "Linkedin",
    //       url: "#",
    //     },
    //   ],
    // },
    {
      image: team03,
      name: "Fortesa Krasniqi",
      designation: "Fullstack Developer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team04,
      name: "Granit Krasniqi",
      designation: "Full Stack Developer",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
    {
      image: team05,
      name: "Armend Binaku",
      designation: "Cyper Security",
      url: "#",
      socials: [
        {
          name: "Facebook",
          url: "#",
        },
        {
          name: "Twitter",
          url: "#",
        },
        {
          name: "Linkedin",
          url: "#",
        },
      ],
    },
  ],
};

import video01 from "@/images/about/3.jpg";

export const VideoOneData = {
  sectionContent: {
    title: "Make amazing websites without touching cod",
    subTitle: "the only design you need",
    text:
      "Tincidunt elit magnis nulla facilisis sagittis maecenas. Sapien nunc amet ultrices, dolores sit ipsum velit purus aliquet, massa fringilla leo orci. Lorem ipsum dolors sit amet elit magnis amet ultrices purus aliquet.",
  },
  video: {
    image: video01,
    ID: "y2Eqx6ys1hQ",
    title: "Watch Video",
  },
};

import testimonial01 from "@/images/home_1/client.jpg";
import testimonial02 from "@/images/home_1/client.jpg";
import testimonial03 from "@/images/home_1/client.jpg";

export const TestimonialsOneData = {
  sectionContent: {
    subTitle: "unsere Kunden",
    title: "zufriedene Kunden",
  },
  posts: [
    {
      name: "Cecilia Colon",
      designation: "Direktorin",
      image: testimonial01,
      content:
        "Bei Solxk, Wir glauben daran, Visionen in digitale Realitäten zu verwandeln.Unser Engagement für Exzellenz und unsere Leidenschaft für Innovation hat uns geholfen, eine Community zufriedener Kunden aufzubauen, die uns ihren Webentwicklungsbedürfnissen vertrauen.Aber nimm nicht nur unser Wort dafür - siehe, was unsere glücklichen Kunden zu sagen haben!",
      date: "25 march, 2024",
    },
    {
      name: "Chase Hanson",
      designation: "Co -Gründer",
      image: testimonial02,
      content:
        "Die Arbeit mit Solxk war ein Spielveränderer für unser Geschäft.Ihr kreativer Ansatz und ihr technisches Fachwissen haben uns geholfen, eine Website zu starten, die nicht nur umwerfend aussieht, sondern auch einwandfrei funktioniert.Unsere Online -Präsenz war noch nie stärker!",
      date: "14 jan, 2024",
    },
    {
      name: "Mittie Flores",
      designation: "manager",
      image: testimonial03,
      content:
        "Die Auswahl von Solxk für unser Webentwicklungsprojekt war die beste Entscheidung, die wir getroffen haben.Ihr Team stand immer zur Verfügung, um unsere Fragen und Bedenken zu beantworten und ein reibungsloses und stressfreies Erlebnis zu gewährleisten.Wir könnten mit den Ergebnissen nicht glücklicher sein!",
      date: "15 april, 2024",
    },
    {
      name: "Carrie Sims",
      designation: "Director",
      image: testimonial01,
      content:
        "Dank Solxk sticht unsere Website in einem wettbewerbsfähigen Markt auf.Sie haben die neuesten Technologien und Best Practices implementiert, was uns einen Wettbewerbsvorteil erzielte.Ihr Fachwissen ist unübertroffen!r Fachwissen ist unübertroffen!",
      date: "25 feb, 2024",
    },
    {
      name: "Anne Stone",
      designation: "Co -Gründer",
      image: testimonial02,
      content:
        "Das Team von Solxk lieferte unser Projekt pünktlich und innerhalb des Budgets, ohne die Qualität zu beeinträchtigen.Ihr optimierter Prozess und ihre professionelle Einstellung machten das gesamte Erlebnis reibungslos und problemlos.Wir sind begeistert von unserer neuen Website!",
      date: "6 april, 2024",
    },
    {
      name: "Hunter Brewer",
      designation: "manager",
      image: testimonial03,
      content:
        "Solxk nahm sich die Zeit, um unsere Marke und unsere Ziele zu verstehen.Sie haben eine Website hergestellt, die unsere Identität wirklich widerspiegelt und unser Publikum einbezieht.Ihre persönliche Berührung und Liebe zum Detail machten den Unterschied",
      date: "10 may, 2024",
    },
  ],
};

import featureTab01 from "@/images/home_1/c22.jpg";
import featureTab02 from "@/images/home_1/c11.png";
import featureTab03 from "@/images/home_1/c33.png";

export const FeatureTabData = {
  sectionContent: {
    title: "Warum uns wählen",
    subTitle: "unsere Vorteileile",
    text:
      "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unseren Mitarbeitern die beste Schulung anbieten. ",
  },
  posts: [
    {
      title: "Neueste Technik",
      content:
        "Die Webentwicklung im Jahr 2024 nutzt hochmoderne Technologien für eine verbesserte Leistung und Benutzererfahrung.Progressive Web Apps (PWAS) bieten ein natives App-ähnliches Erlebnis mit Offline-Funktionalität und schnelleren Ladezeiten.Single -Page -Anwendungen (SPAS), die mit Frameworks wie React und Vue.js erstellt wurden, bieten nahtlose Benutzerinteraktionen, indem Inhalte dynamisch geladen werden.Das Server-Side-Rendering (SSR) mit Frameworks wie Next.js verbessert die SEO- und Ladezeiten, indem herkömmliche mehrseitige Vorteile mit modernem Spa kombiniert werden. ",
      image: featureTab01,
      lists: [
        {
          item: "Self-contained, state-of-the-art technology stack",
        },
        {
          item: "Scalability",
        },

        {
          item: "Integration Capabilities",
        },
        {
          item: "Robust Security Measures",
        },
      ],
    },
    {
      title: "Großartige Unterstützung",
      content:
        "Bei Solxk sind wir stolz darauf, unseren Kunden außergewöhnliche Unterstützung zu bieten.Wir verstehen, dass technische Probleme auftreten können, und wenn dies der Fall ist, benötigen Sie sofortige und effektive Unterstützung, um Ihre Projekte reibungslos zu halten.Hier ist, warum unsere Unterstützung auffällt:u halten.Hier ist, warum unsere Unterstützung auffällt: ",
      image: featureTab02,
      lists: [
        {
          item: "Reaktionsschnelle Unterstützungerstützung",
        },
        {
          item: "Fachwissen und Wissen",
        },
        {
          item: "Personalisierte Lösungen",
        },
        {
          item: "Ständige Verbesserung",
        },
      ],
    },
    {
      title: "Sicherheit",
      content:
        "Bei Solxk verstehen wir die kritische Bedeutung der Sicherheit in der heutigen digitalen Landschaft.Da sich die Cyber-Bedrohungen schnell entwickeln, ist es nicht verhandelbar, Ihre Unternehmens- und Kundendaten zu schützen.So gewährleisten wir Ihren Seelenfrieden:. ",
      image: featureTab03,
      lists: [
        {
          item: "Comprehensive Security Measures",
        },
        {
          item: "Proactive Threat Detection",
        },
        {
          item: "Continuous Improvement and Adaptation",
        },
        {
          item: "Robust Data Encryption",
        },
      ],
    },
  ],
};
import Solxk from "@/images/home_1/Soltrikslogo.png";

export const ParallaxOneData = {
  iconName: Solxk,
  title: "Erfolg mit dem Erfolg mit ",
  specialText: "Soltrisch",
  text:
    "Erfolg ist nicht nur ein Ziel;Es ist eine Reise, die wir mit jedem Kunden, jedem Projekt und jeder Innovation begeben.Unser Engagement für Exzellenz und unser unerbittliches Streben nach Perfektion haben uns in den Vordergrund der Branche getrieben und US -Anerkennung und Vertrauen von Kunden auf der ganzen Welt verdient.",
};

export const PortfolioHomeData = {
  sectionContent: {
    title: "arbeitsplanung",
    subTitle: "Unser Portfolio",
    text:
      "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten, während wir unseren Mitarbeitern die beste Schulung anbieten.",
  },
};

import video02 from "@/images/home_1/3.jpg";
import aboutTwo01 from "@/images/home_1/2.jpg";
import aboutTwo02 from "@/images/home_1/1.jpg";

export const AboutTwoData = {
  sectionContent: {
    title: "",
    subTitle: "",
    text:
      "Bei Solxk sind wir nicht nur eine andere Firma.Wir sind Meister unserer Expertise und setzen sich für innovative Strategien ein, um beispiellose Lösungen zu liefern.Als führender Anbieter von Entwicklungs- und Cybersicherheitsdiensten sind wir stolz auf unser unerschütterliches Engagement für Exzellenz.",
    text2:
      "Bei Solxk können Sie sicher sein, dass Sie mit den besten in der Branche zusammenarbeiten.Von der Entwicklung der Technik bis hin zu robusten Cybersicherheitslösungen haben wir Sie umfassend abgedeckt.Machen Sie mit uns auf dem Weg zum Beherrschen digitaler Lösungen, bei denen jede Herausforderung mit Innovation begegnet ist und jedes Projekt mit Präzision ausgeführt wird.",
  },
  button: {
    label: "Erfahren Sie mehr",
    url: "/about",
  },
  gallery: [
    // aboutTwo01,
    aboutTwo02,
  ],
};

import featureTwo01 from "@/images/home_1/5.jpg";

export const FeatureTwoData = {
  sectionContent: {
    title: "echte Erfahrung",
    subTitle: "Unsere Kernmerkmale",
    text:
      "Wir sind bestrebt, unseren Kunden einen außergewöhnlichen Service zu bieten und unseren Mitarbeitern die beste Schulung zu bieten.",
  },
  posts: [
    {
      title: "Keine Codierungsfähigkeiten erfordern",
      text:
        "Es gibt viele Variationen von Passagen von Lorem Ipsum, aber die Mehrheit hat gelitten.",
    },
    {
      title: "Online -Dokumentation",
      text:
        "Es gibt viele Variationen von Passagen von Lorem Ipsum, aber die Mehrheit hat gelitten.",
    },
    {
      title: "SEO optimiert",
      text:
        "Es gibt viele Variationen von Passagen von Lorem Ipsum, aber die Mehrheit hat gelitten.",
    },
  ],
  image: {
    text: "Total Design Freedom \n für jeden.",
    path: featureTwo01,
  },
};

export const CallToActionTwoData = [
  {
    label: "Sehen Sie sich unsere jüngste Arbeit an",
    url: "/portfolio",
  },
  {
    label: "Fordern Sie ein kostenloses Angebot an",
    url: "/contact",
  },
];
